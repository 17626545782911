import '../App.css';
import React from 'react';
import ContactSupport from './components/contact';

function Home() {
    return (
        <div className="App-header home">
            <h1>Welcome!</h1>
            <div className='circle'>
                <img src='../logo.png' className="App-logo-big" alt="logo" />
            </div>
            <p>A better way to view iFLY availability in the UK</p>
            <p>Pick a location from the header</p>
            <ContactSupport />
        </div>
    );
}

export default Home;