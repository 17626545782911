import { getWeeks } from '../utils/dateUtils';
import '../../App.css'

function WeekPicker({ onChange }) {

    return (
        <select onChange={onChange}>
            {
                getWeeks().map(([index, title, isSection]) =>
                    <option value={index} key={index} disabled={isSection}>{title}</option>
                )
            }
        </select>
    );
}

export default WeekPicker;