import '../../App.css';

function ContactSupport() {
    return (
        <div className='email-link'>
            🍍
            <a href="mailto:support@crazypineapple.uk?subject=iFLY UK Manifest Support&body=Hello There,%0D%0A%0D%0A" target='_blank' rel="noreferrer">
                Contact Support
            </a>
            🤪
        </div>
    );
}

export default ContactSupport;