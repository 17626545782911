import '../../App.css';
import getColour from '../utils/colours';
import { isoDateString } from '../utils/dateUtils';

const [oClock, halfPast] = [":00", ":30"];

function filteredHours(filter) {
  const morning = filter.length === 0 || filter.indexOf('morning') !== -1;
  const afternoon = filter.length === 0 || filter.indexOf('afternoon') !== -1;
  const evening = filter.length === 0 || filter.indexOf('evening') !== -1;
  const hours = [];
  let blankIdx = 0;
  for (let i = 8; i <= 23; i++) {
    // Deliberately add in some blank rows to indicate filter
    if (i === 12 && (morning || evening) && !afternoon) {
      hours.push(`blank${blankIdx++}`);
      continue;
    }
    else if (i === 11 && afternoon && !morning) {
      hours.push(`blank${blankIdx++}`);
      continue;
    }
    else if (i === 17 && afternoon && !evening) {
      hours.push(`blank${blankIdx++}`);
      continue;
    }
    // Normal filtering
    if (i < 12 && !morning)
      continue;
    if (i >= 12 && i < 17 && !afternoon)
      continue;
    if (i >= 17 && !evening)
      continue;
    const hourStr = String(i).padStart(2, "0");
    hours.push(hourStr);
  }
  return hours;
}

function DayManifest({ date, manifestData, filter }) {

  const dateOptions = {
    month: 'short',
    day: 'numeric',
  };

  const dateStr = date.toLocaleDateString(undefined, dateOptions);
  if (!manifestData) {
    return <p>Loading...</p>;
  }

  const disabled = isoDateString(date) < isoDateString(new Date());

  const dayStyle = date.getDay() === 0 ? 'sunday-table' : date.getDay() === 6 ? 'saturday-table' : 'day-table'
  const headerStyle = date.getDay() === 0 || date.getDay() === 6 ? 'weekend-header' : '';
  return (
    <table className={`${dayStyle} ${disabled ? "disabled-table" : ""}`}>
      <tbody>
        <tr>
          <td colSpan={2} className={`table-header ${headerStyle}`}>{dateStr}</td>
        </tr>
        {
          filteredHours(filter).map((hour) => {
            if (hour.startsWith("blank"))
              return (<tr key={hour}><td>...</td></tr>);
            return [oClock, halfPast].map((timeSuffic) =>
              <tr key={hour + timeSuffic}>
                <td
                  title={`${manifestData[hour + timeSuffic]?.qtyavailable ?? 0} min available.`}
                  style={{ "backgroundColor": getColour(manifestData[hour + timeSuffic]?.qtyavailable ?? 0) }}
                >
                  {hour + timeSuffic}
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  );
}

export default DayManifest;