import '../App.css';
import React from 'react';

export class NoPage extends React.Component {

    render() {
        return (
          <div className="App">
            404!
          </div>
        )
    }
}; 

export default NoPage;