import getColour from '../utils/colours';

function Legend() {

  return (
    <table className="legend-table">
      <tbody>
        <tr>
          <td className='no-border'>Available: </td>
          <td style={{ backgroundColor: getColour(30) }}> 30 min</td>
          <td style={{ backgroundColor: getColour(15) }}> 15 min</td>
          <td style={{ backgroundColor: getColour(10) }}> 10 min</td>
          <td style={{ backgroundColor: getColour(5) }}> 5 min</td>
          <td style={{ backgroundColor: getColour(0) }}> Nothing</td>
        </tr>
      </tbody>
    </table>
  );
}

export default Legend;