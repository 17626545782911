import '../../App.css'
import * as ToggleGroup from '@radix-ui/react-toggle-group';

function TimeOfDay({ filter, onChange }) {

    return (
        <ToggleGroup.Root
            type="multiple"
            className='ToggleGroup'
            value={filter}
            onValueChange={onChange}
        >
            <ToggleGroup.Item className='ToggleGroupItem' value="morning">
                Morning
            </ToggleGroup.Item>
            <ToggleGroup.Item className='ToggleGroupItem' value="afternoon">
                Afternoon
            </ToggleGroup.Item>
            <ToggleGroup.Item className='ToggleGroupItem' value="evening">
                Evening
            </ToggleGroup.Item>
        </ToggleGroup.Root>
    );
}

export default TimeOfDay;