import '../App.css';
import './toggle-group.css';
import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import WeekManifest from './components/week';
import Legend from './components/legend';
import WeekPicker from './components/weekPicker';
import TimeOfDay from './components/timeOfDay';
import ContactSupport from './components/contact';
const uuid = require('uuid');

const _locationMap = {
  "lon": ["London", "13"],
  "bas": ["Basingstoke", "3"],
  "man": ["Manchester", "2"],
  "mk": ["Milton Keynes", "1"],
};

function TunnelManifest() {
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState([]);
  const [sessionId] = useState(uuid.v4());
  const [weekNumber, setWeekNumber] = useState(0);
  const params = useParams();
  const locationShortStr = params['location'];
  const [locationStr, locationId] = _locationMap[locationShortStr];
  if (data !== locationShortStr)
    setData(locationShortStr);

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className='circle'>
          <img src='../logo.png' className="App-logo" alt="logo" />
        </div>
        <div className='dropdown'>
          Tunnel - {locationStr} -
          <WeekPicker
            onChange={(e) => {
              setWeekNumber(parseInt(e.target.value));
            }}
          />
        </div>
        <TimeOfDay
          filter={filter}
          onChange={(value) => {
            if (value)
              setFilter(value);
          }}
        />
        <WeekManifest
          weekNumber={weekNumber}
          locationId={locationId}
          sessionId={sessionId}
          filter={filter}
        />
        <Legend />
        <ContactSupport />
      </header>
    </div>
  );
}

export default TunnelManifest;