import '../../App.css';
import React, { useEffect, useState, useMemo } from 'react';
import DayManifest from './singleDay';
import { isoDateString } from '../utils/dateUtils';


function WeekManifest({ weekNumber, locationId, sessionId, filter }) {
  const [manifestData, setManifestData] = useState([]);

  const dates = useMemo(() => {
    const calcedDates = []
    let prevMonday = new Date();
    prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
    prevMonday.setDate(prevMonday.getDate() + 7 * weekNumber);
    for (let i = 0; i < 7; i++) {
      const copiedDate = new Date(prevMonday.getTime());
      copiedDate.setDate(copiedDate.getDate() + i);
      calcedDates.push(copiedDate);
    }
    return calcedDates;
  }, [weekNumber]);

  useEffect(() => {
    dates.forEach((date, i) => {
      const dateStr = isoDateString(date);
      setManifestData([]);
      const proxyURL = `https://bookings.iflyworld.co.uk/booking/ajax.php?getBookingTimes=1&productId=1951&locationId=${locationId}&qty=1&selectedDate=${dateStr}&block=95881&seed=${sessionId}`
      const url = 'https://corsproxy.io/?' + encodeURIComponent(proxyURL);
      fetch(url)
        .then(response => response.json())
        .then(manifestJson => {
          setManifestData((prev) => {
            const newArray = [...prev];
            newArray[i] = manifestJson;
            return newArray;
          });
        });
    });
  }, [weekNumber, locationId, sessionId, dates]);

  return (
    <table className="manifest-table">
      <tbody>
        <tr>
          {dates.map((date, i) =>
            <td key={date} className={i >= 5 ? 'weekend-header' : ''}>
              {date.toLocaleDateString(undefined, { weekday: 'short' })}
            </td>
          )}
        </tr>
        <tr>
          {dates.map((date, i) =>
            <td key={date}>
              <DayManifest
                key={isoDateString(date)}
                date={date}
                manifestData={manifestData[i]}
                filter={filter}
              />
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
}

export default WeekManifest;