export function getColour(available) {
    if (available === 30) {
        return "green";
    }
    else if (available >= 15) {
        return "rgb(119 206 3)";
    }
    else if (available >= 10) {
        return "orange";
    }
    else if (available >= 5)
        return "red";
    else
        return "";
}

export default getColour;