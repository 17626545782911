
export function getPrevMonday() {
    let prevMonday = new Date();
    prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
    return prevMonday
}

export function getNextSunday(monday) {
    const copiedDate = new Date(monday.getTime());
    copiedDate.setDate(copiedDate.getDate() + 6);
    return copiedDate;
}

export function dateRangeStr(date1, date2) {
    const day1 = date1.toLocaleDateString(undefined, { day: 'numeric' });
    const day2 = date2.toLocaleDateString(undefined, { day: 'numeric' });
    const month1 = date1.toLocaleDateString(undefined, { month: 'short' })
    if (date1.getMonth() === date2.getMonth()) {
        return `${day1}-${day2} ${month1}`;
    }
    else {
        const month2 = date2.toLocaleDateString(undefined, { month: 'short' })
        return `${day1}${month1} - ${day2}${month2}`;
    }
}

export function isoDateString(date) {
    const z = date.getTimezoneOffset() * 60 * 1000;
    const dLocal = new Date(date - z);
    return dLocal.toISOString().substring(0, 10);    
}

export function getWeeks() {
    const weeks = [];
    const prevMonday = getPrevMonday()
    for (let i = 0; i <= 16; i++) {
        let name;
        const mon = new Date(prevMonday.getTime());
        mon.setDate(mon.getDate() + 7 * i);
        const sun = getNextSunday(mon);
        if (i === 0) {
            name = "This Week";
        } else if (i === 1) {
            name = "Next Week"
        } else {
            name = dateRangeStr(mon, sun);
        }
        weeks.push([`${i}`, name, false]);
        if (sun.getMonth() !== mon.getMonth())
            weeks.push([`section ${i}`, sun.toLocaleDateString(undefined, { month: 'long' }), true]);
    }
    return weeks;
}

const funcs = { getPrevMonday, getNextSunday, dateRangeStr, getWeeks, isoDateString };
export default funcs;