import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/manifest/mk">Milton Keynes</Link>
        </li>
        <li>
          <Link to="/manifest/bas">Basingstoke</Link>
        </li>
        <li>
          <Link to="/manifest/man">Manchester</Link>
        </li>
        <li>
          <Link to="/manifest/lon">London</Link>
        </li>
        <div className="ifly-login">
          <button type="button" value="Login" className="ifly-btn" onClick={() => window.open("https://bookings.iflyworld.co.uk/booking/superuser_redeem.php", "_blank")}>
            Super User Login
          </button>
        </div>
      </ul>
    </nav>
  );
}

export default Navbar;
